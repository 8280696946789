<template>
    <div class="compromiso" :open="open">
        <p @click="open = !open">
            {{ data.compromiso }}
        </p>

        <a class="show" @click="open = !open"></a>

        <div v-if="open">
            <div class="contenedor-cumplimiento row-between">
                <!-- <h2>Grado de cumplimiento del compromiso:</h2> -->

                <!-- <div class="progress-bar-wrapper" :title="`Grado cumplimiento del compromiso: ${data.cumplimiento}%`">
                    <div class="progressbar -green">
                        <span class="fill" :style="`width:${data.cumplimiento}%;`"></span>
                    </div>
                    <span class="percent -green" :style="`left:${data.cumplimiento}%`">{{data.cumplimiento}}%</span>
                </div> -->

            </div>

            <template v-if="valorativos.length != 0">

                <h3>Indicadores de cumplimiento</h3>

                <div class="indicadores-compromiso">

                    <div class="indicador-compromiso row-start" v-for="(indicador, key) in valorativos" :key="key">
                        <p>{{ indicador.nombre }}</p>




                        <div class="row-start" v-if="indicador.cumplimiento != 0 && indicador.cumplimiento">
                            <span class="mini-progress-bar" v-if="indicador.tipo == 'Valorativo'">
                                <span class="progress" :style="`width:${indicador.cumplimiento}%`"
                                    :title="`Porcentaje completado:${indicador.cumplimiento}%`"
                                    :full="indicador.cumplimiento == 100"></span>
                            </span>
                            <figure>{{ indicador.cumplimiento }}%</figure>
                        </div>



                        <p class="help"
                            v-if="(indicador.cumplimiento == 0 | !indicador.cumplimiento) && indicador.AnioUltimaCarga && indicador.AnioUltimaCarga <= anio - 1">
                            Dato no disponible</p>

                        <p class="help"
                            v-if="(indicador.cumplimiento == 0 | !indicador.cumplimiento) && indicador.AnioCreacion == anio">
                            Indicador nuevo, sin datos</p>

                        <router-link v-if="!checkroutepublico()" class="enlace-cover"
                            :title="(indicador.cumplimiento != 0) ? `Grado cumplimiento del indicador: ${indicador.cumplimiento}%` : 'Indicador de nueva creación'"
                            :to="$t('enlaces.indicadorcartas') + '/' + indicador.indicadorid"></router-link>
                        <router-link v-if="checkroutepublico()" class="enlace-cover"
                            :title="(indicador.cumplimiento != 0) ? `Grado cumplimiento del indicador: ${indicador.cumplimiento}%` : 'Indicador de nueva creación'"
                            :to="$t('enlaces.indicadorpublico') + '/' + indicador.indicadorid"></router-link>
                    </div>
                </div>

            </template>

            <template v-if="informativos.length != 0">

                <h3>Indicadores informativos</h3>

                <div class="indicadores-compromiso">

                    <div class="indicador-compromiso row-start" v-for="(indicador, key) in informativos" :key="key">
                        <p>{{ indicador.nombre }}</p>

                        <router-link v-if="!checkroutepublico()" class="enlace-cover"
                            :to="$t('enlaces.indicadorcartas') + '/' + indicador.indicadorid">
                        </router-link>
                        <router-link v-if="checkroutepublico()" class="enlace-cover"
                            :to="$t('enlaces.indicadorpublico') + '/' + indicador.indicadorid">
                        </router-link>
                    </div>
                </div>

            </template>

        </div>

    </div>
</template>



<script>

import { mapGetters } from 'vuex';

export default {
    props: ['data'],
    data: () => ({
        open: false
    }),
    methods: {
        checkroutepublico() {
            if (this.$route.path.includes('publico')) {
                return true;
            } else {
                return false;
            }
        }
    },
    computed: {
        ...mapGetters({
            compromiso: 'getCompromisobyCarta',
            indicadores: 'getIndicadoresbyCompromiso'
        }),
        valorativos() {
            let arrindicadores = this.indicadores(this.data.compromisoid).filter(e => e.tipo == 'Valorativo');

            // Creo un nuevo arreglo con solo los campos que me interesan 
            arrindicadores = arrindicadores.map(element => ({
                nombre: element.nombre,
                tipo: element.tipo,
                indicadorid: element.indicadorid,
                AnioUltimaCarga: element.AnioUltimaCarga,
                AnioCreacion: element.AnioCreacion,
                cumplimiento: element.cumplimiento
            }));


            // Elimino los elementos duplicados por indicadorid usando un objeto Set 
            arrindicadores = [...new Set(arrindicadores.map(e => e.indicadorid))].map(id => arrindicadores.find(e => e.indicadorid == id));

            return arrindicadores;

            //return this.indicadores(this.data.compromisoid).filter(e => e.tipo == 'Valorativo');
        },
        informativos() {
            // Obtengo los indicadores de tipo ‘Informativo’ del compromiso 
            let arrindicadores = this.indicadores(this.data.compromisoid).filter(e => e.tipo == 'Informativo');

            // Creo un nuevo arreglo con solo los campos que me interesan 
            arrindicadores = arrindicadores.map(element => ({
                nombre: element.nombre,
                tipo: element.tipo,
                indicadorid: element.indicadorid,
                AnioUltimaCarga: element.AnioUltimaCarga,
                AnioCreacion: element.AnioCreacion,
                cumplimiento: element.cumplimiento
            }));

            // Elimino los elementos duplicados por indicadorid usando un objeto Set 
            arrindicadores = [...new Set(arrindicadores.map(e => e.indicadorid))].map(id => arrindicadores.find(e => e.indicadorid == id));

            return arrindicadores;

            //return this.indicadores(this.data.compromisoid).filter(e => e.tipo == 'Informativo');
        },
        anio() {
            return new Date().getFullYear();
        }
    }
}

</script>


<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

.progress-bar-wrapper {
    width: 100%;
    max-width: 280px;

    .progressbar {
        background: white;
        border: 1px solid $primary;
    }
}
</style>